import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Table
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from "react-notifications";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

  
  // rowSelection object indicates the need for row selection
  

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Registration = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const {  loading,createClass,success,setSuccess,editClass,updateClass } = useFormDetail();

  useEffect (() => {
   if(success){
    //form.resetFields();
    NotificationManager.success('Class Saved Successfully', 'Success', 3000);
    setSuccess(false);
    form.resetFields();
    setTimeout(() => {
    history.push("/main/class-list");
    }, 1000);

   }
  }, [success]);
  useEffect (() => {
    if(editClass){
    //alert(JSON.stringify(editClass));
    }
   }, [editClass]);


  const onFinish = values => {
    console.log('Received values of form: ', values);
    updateClass(values,editClass.id)
  };

  
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = value => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map(domain => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map(website => ({
    label: website,
    value: website,
  }));

  return (
      <div>
    <Card className="gx-card" title="Class Update">
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
     
      
      <Form.Item
        name="classname"
        initialValue={editClass.classname}      
        label={
           <span>
            Class Name&nbsp; 
           </span>
        }
        rules={[{ required: true, message: 'Please input Class Name!', whitespace: true }]}
      >
        <Input value={editClass && editClass.classname} />
        </Form.Item>
        <Form.Item
        name="classcode"
        initialValue={editClass.classcode}
        label={
          <span>
            Class Code&nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please input Class code!', whitespace: true }]}
      >
        <Input />
        </Form.Item>
        <Form.Item
        name="classdescription"
        initialValue={editClass.classdescription}
        label={
          <span>
            Class Description&nbsp;
           
          </span>
        }
        rules={[{  message: 'Please input Class Description!', whitespace: true }]}
      >
        <Input />
      </Form.Item>

     
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" loading={loading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <NotificationContainer/>
    </Card>
   
  </div>
  );
};

export  default Registration;

