import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,
  Popconfirm,
  Tag,
  Modal,
} from "antd";
import { Table } from "ant-table-extensions";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useFormDetail } from "../../../util/dataProvider";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

const Registration = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  let history = useHistory();

  const [visible, setVisible] = useState(false);
  const [editvisible, setEditvisible] = useState(false);
  const {
    loading,
    success,
    setSuccess,
    getBookNoteList,
    bookList,
   // setEditBook,
    getClassList,
    getSubjectListByClass,
    createBook,
    classList,
    subjectListByClass,
    getDeleteBook,
    updateBook
  } = useFormDetail();
  const [showMessage, setShowMessage] = useState('');
  const [editBook, setEditBook] = useState(null);
  const columns = [
  
    {
      title: "Stationery Name",
      dataIndex: "name",
      // render: text => <span className="gx-link">{text}</span>,
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <span className="gx-link">
            <Tag
              onClick={() => {
               
               setEditvisible(true);
               setEditBook(record);
               editform.setFieldsValue({
                name: record.name,
                id: record.id,
               });
                //history.push("/main/edit-book");
              }}
              color="#038fdd"
            >
              Edit
            </Tag>
          </span>
          <span className="gx-link">
            <Popconfirm
              title="Are you sure want to delete this stock?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                type: "default",
                style: { backgroundColor: "#A2A08A4D", color: "#000" },
              }}
              cancelButtonProps={{
                type: "default",
                style: { backgroundColor: "red", color: "#fff" },
              }}
            >
              <Tag color="#f50">Delete</Tag>
            </Popconfirm>
          </span>
        </span>
      ),
    },
  ];
  useEffect(() => {
    if (success) {
      //form.resetFields();
      NotificationManager.success(showMessage, "Success");

      setSuccess(false);
      form.resetFields();
      setTimeout(() => {
        getBookNoteList({ type: "stationery" });
        setVisible(false);
        setEditvisible(false);
        NotificationManager.listNotify.forEach((n) =>
          NotificationManager.remove({ id: n.id })
        );
       // history.push("/main/books-type");
        setShowMessage("");
      }, 1000);
    }
  }, [success]);

  useEffect(() => {
    getClassList();
    getSubjectListByClass();
    getBookNoteList({ type: "stationery" });
  }, []);
  const onDelete = (id) => {
    getDeleteBook({ id: id });
    setShowMessage("Stationery Deleted Successfully");
  };

  const onFinish = (values) => {
    // alert(JSON.stringify(values));
    console.log("Received values of form: ", values);
    values.category = "stationery";
    createBook(values);
    setShowMessage("Stationery Added Successfully");
  };
  const onFinishEdit = (values) => {
    values.id = editBook.id;
    updateBook(values);
    setShowMessage("Stationery Updated Successfully");
  };
  function handleChange(value) {
    // getSubjectListByClass({classId:value})
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log("blur");
  }

  function handleFocus() {
    console.log("focus");
  }
  const handleOk = () => {
    // this.setState({
    //   ModalText: 'The modal will be closed after two seconds',
    //   confirmLoading: true,
    // });
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };
  const handleCancel = () => {
    editform.resetFields();
    setEditBook(null);
    console.log("Clicked cancel button");
    setVisible(false);
    setEditvisible(false);
   
   
  };

  return (
    <div>
      <Card
        title="Stationery List"
        extra={
          <Button type="primary" onClick={() => setVisible(true)}>
           Add Stationery
          </Button>
        }
      >
        <Table
          className="gx-table-responsive"
          bordered
          columns={columns}
          dataSource={bookList}
          searchable
        />

        <NotificationContainer />
      </Card>
      <Modal
        title="Add Stationery"
        visible={visible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="bookRegister"
          onFinish={onFinish}
          scrollToFirstError
        >
         
         

          <Form.Item
            name="name"
            label={<span>Stationery name&nbsp;</span>}
            rules={[
              {
                required: true,
                message: "Please enter stationery name",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter stationery Name" />
          </Form.Item>

         

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title="Edit Stationery"
        visible={editvisible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
      
        <Form
          {...formItemLayout}
          form={editform}
          name="editBookRegister"
          onFinish={onFinishEdit}
          scrollToFirstError
        >
         

         <Form.Item
            name="name"
            label={<span>Stationery name&nbsp;</span>}
            rules={[
              {
                required: true,
                message: "Please enter stationery name",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter stationery Name" />
          </Form.Item>

        
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      
      </Modal>
    </div>
  );
};

export default Registration;
