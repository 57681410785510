import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Table
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import {NotificationContainer, NotificationManager} from "react-notifications";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

  
  // rowSelection object indicates the need for row selection
  

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Registration = () => {
  const [form] = Form.useForm();
  const {  loading,createSubject,success,setSuccess,getClassList,classList } = useFormDetail();

  useEffect (() => {
    
   if(success){
    //form.resetFields();
    NotificationManager.success('Subject Saved Successfully', 'Success', 3000);
    setSuccess(false);
    form.resetFields();

   }
  }, [success]);

  useEffect (() => {
    getClassList()
   }, []);


  const onFinish = values => {
   // alert(JSON.stringify(values));
    console.log('Received values of form: ', values);
    createSubject(values)
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log('blur');
  }

  function handleFocus() {
    console.log('focus');
  }
  
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = value => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map(domain => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map(website => ({
    label: website,
    value: website,
  }));
  const Option = Select.Option;
  return (
      <div>
    <Card className="gx-card" title="Subject Registration">
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
     
      
      <Form.Item
        name="subjectname"
        label={
          <span>
            Subject Name&nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please input subject Name!', whitespace: true }]}
      >
        <Input />
        </Form.Item>
        <Form.Item
        name="subjectcode"
        label={
          <span>
            Subject Code&nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please input subject code!', whitespace: true }]}
      >
        <Input />
        </Form.Item>

        {/* <Form.Item
        name="classId"
        label={
          <span>
            Class &nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
      >
        <Select
        showSearch
       
        name="classId"
        placeholder="Select a class"
        optionFilterProp="children"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
       {classList.map(item => (
          <Option key={item.id} value={item.id}>
            {item.classname}
          </Option>
        ))}
        
      </Select>
      </Form.Item> */}
        <Form.Item
        name="subjectdescription"
        label={
          <span>
            Subject Description&nbsp;
           
          </span>
        }
        rules={[{  message: 'Please input Class Description!', whitespace: true }]}
      >
        <Input />
      </Form.Item>

     
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" loading={loading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <NotificationContainer/>
    </Card>
   
  </div>
  );
};

export  default Registration;

