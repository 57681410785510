import React, { useEffect, useState } from "react";
import { Col, Row, Button, Card, Form, Input, Select, DatePicker, Spin } from "antd";

import { useFormDetail } from "../../../util/dataProvider";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router-dom";


const FormItem = Form.Item;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

const StudentStockFormEdit = () => {
  const [form] = Form.useForm();
  const {
    loading,
    createSubject,
    success,
    setSuccess,
    getClassList,
    classList,
    createCat,
    getSubjectListByClass,
    subjectListByClass,
    stockIssue,
    getStationeryList,
    stationeryList,
    Createclasswise,
    getBookListAll,
    bookListAll,
	stockrecord,
	updateStockData,
  setEditStocklist,
  editStocklist
  } = useFormDetail();
  let history = useHistory();

  const [classId, setClassId] = useState("");
  const [record, setRecord] = useState(null);

  useEffect(() => {
	if(stockrecord && stockrecord.length > 0)
	{
		setRecord(stockrecord[0]);
		handleChangeClass(stockrecord[0].classId);
		//console.log("stockrecord", stockrecord);
   	}
  }, [stockrecord]);

  useEffect(() => {
    if(!editStocklist){
      history.push('/main/class-wise-list');
    }else{
      getClassList();
      getStationeryList();
    }
   
  }, []);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
   	updateStockData(values);
  };

  useEffect (() => {
    
	if(success){
	 //form.resetFields();
	 NotificationManager.success('Updated Successfully', 'Success');
   setEditStocklist(false);
	 setSuccess(false);
	 //form.resetFields();
	 setTimeout(() => {
	   NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
	   history.push('/main/class-wise-list');
	 }, 1000);
 
	}
   }, [success]);

  const handleChangeClass = (value) => {
    setClassId(value);
    getSubjectListByClass({ classId: value });
    getBookListAll({ classId: value });
  };

  //handleChangeSubjectByBook
  const handleChangeSubjectByBook = (fields) => {
    console.log(fields);
  };

  return (
	stockrecord && stockrecord.length > 0 && record?
    <Form
      form={form}
      autoComplete="off"
      //initialValues={{ remember: true }}
      name="basic"
      onFinish={onFinish}
      //onFinishFailed={this.onFinishFailed}
      layout="inline"
      initialValue={ record }
    >
	<FormItem label="Class" name="id" initialValue={record.id}>
		<Input name="id" hidden />
	</FormItem>
    <Card
        className="gx-card"
        title="Class wise assign"
        style={{ width: "100%" }}
    >
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <FormItem
              name="classId"
              rules={[
                {
                  required: true,
                  message: "Please choose class.",
                },
              ]}
              label={<span>Select Class &nbsp;</span>}
              initialValue={ record.classId}
            >
				
              <Select
			  	name="classId"
                showSearch
                style={{ width: 250 }}
                placeholder="Select a class"
                optionFilterProp="children"
                onChange={handleChangeClass}
                //onFocus={handleFocus}
                //onBlur={handleBlur}
				//initialValue={parseInt(record.classId)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
				//defaultValue={record && record.length > 0 ? parseInt(record[0].classId) : ""}
              >
                {classList &&
                  classList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.classname}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Card>

      {/* Books formlist */}
      <Card
        className="gx-card"
        title="Add Books entry"
        style={{ width: "100%" }}
      >
        <Form.List name="books" initialValue={record.books}>
          {(fields, { add, remove }) => {
            return (
              <div>
                <Row>
                  {fields.map((field, index) => (
                    <>
                      <Col span={7}>
                        <Form.Item
                          label={false}
                          {...field}
                          name={[field.name, "subjectId"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select subject",
                            },
                          ]}
                          initialValue={ record.books && record.books[index] ?
                            record.books[index].subjectId :  null
                          }
                        >
                          <Select
                            showSearch
                            allowClear
                            style={{ width: 250 }}
                            placeholder="Select subject"
                            optionFilterProp="children"
                            //onChange={handleChangeClass}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subjectListByClass &&
                              subjectListByClass.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.subjectname}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label={false}
                          {...field}
                          name={[field.name, "bookId"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Book",
                            },
                          ]}
                          initialValue={record.books && record.books[index] ? record.books[index].bookId :  null}
                        >
                          <Select
                            showSearch
                            allowClear
                            style={{ width: 250 }}
                            placeholder="Select Text Book"
                            optionFilterProp="children"
                            onChange={() => handleChangeSubjectByBook(fields)}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {bookListAll &&
                              bookListAll.map((item) => {
                                return (
                                  item.category == "book" && (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  )
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <FormItem
                          {...field}
                          name={[field.name, "qty"]}
                          label={false}
                          initialValue={record.books && record.books[index] ? record.books[index].qty : null}
                          rules={[
                            {
                              required: true,
                              message: "Please enter quantity.",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Quantity" />
                        </FormItem>
                      </Col>
                      <Col span={3}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginTop: 10 }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "20%" }}
                  >
                    <PlusOutlined /> Add Books
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Card>

      {/* Note formlist */}
      <Card
        className="gx-card"
        title="Note book entry"
        style={{ width: "100%" }}
      >
        <Form.List name="notebooks" initialValue={record.notebooks}>
          {(fields, { add, remove }) => {
            return (
              <div>
                <Row>
                  {fields.map((field, index) => (
                    <>
                      <Col span={7}>
                        <Form.Item
                          label={false}
                          {...field}
                          name={[field.name, "subjectId"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select subject",
                            },
                          ]}
                          initialValue={ record.notebooks && record.notebooks[index] ?
                            record.notebooks[index].subjectId : null
                          }
                        >
                          <Select
                            showSearch
                            allowClear
                            style={{ width: 250 }}
                            placeholder="Select subject"
                            optionFilterProp="children"
                            //onChange={handleChangeClass}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subjectListByClass &&
                              subjectListByClass.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.subjectname}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          label={false}
                          {...field}
                          name={[field.name, "notebookId"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select Book",
                            },
                          ]}
                          initialValue={ record.notebooks && record.notebooks[index] ?
                            record.notebooks[index].notebookId :  null
                          }
                        >
                          <Select
                            showSearch
                            allowClear
                            style={{ width: 250 }}
                            placeholder="Select Note Book"
                            optionFilterProp="children"
                            onChange={() => handleChangeSubjectByBook(fields)}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {bookListAll &&
                              bookListAll.map((item) => {
                                return (
                                  item.category == "notebook" && (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  )
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <FormItem
                          {...field}
                          name={[field.name, "qty"]}
                          label={false}
                          initialValue={record.notebooks && record.notebooks[index] ? record.notebooks[index].qty : null}
                          rules={[
                            {
                              required: true,
                              message: "Please enter quantity.",
                            },
                          ]}
                        >
                          <Input placeholder="qty" />
                        </FormItem>
                      </Col>
                      <Col span={3}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginTop: 10 }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "25%" }}
                  >
                    <PlusOutlined /> Issue note book
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Card>

      {/* Stationary formlist */}
      <Card
        className="gx-card"
        title="Stationary entry"
        style={{ width: "100%" }}
      >
        <Form.List name="stationery" initialValue={record.stationery}>
          {(fields, { add, remove }) => {
            return (
              <div>
                <Row>
                  {fields.map((field, index) => (
                    <>
                      <Col span={10}>
                        <Form.Item
                          label={false}
                          {...field}
                          name={[field.name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter book name.",
                            },
                          ]}
                          initialValue={ record.stationery && record.stationery[index] ? 
                            record.stationery[index].name : null
                          }
                        >
                          <Select
                            showSearch
                            allowClear
                            style={{ width: 250 }}
                            placeholder="Select a stationary"
                            optionFilterProp="children"
                            //onChange={handleChangeClass}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {stationeryList &&
                              stationeryList.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <FormItem
                          {...field}
                          name={[field.name, "qty"]}
                          label={false}
                          initialValue={ record.stationery && record.stationery[index] ? 
                            record.stationery[index].qty : null
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter quantity.",
                            },
                          ]}
                        >
                          <Input placeholder="qty" />
                        </FormItem>
                      </Col>
                      <Col span={4}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ margin: "0 8px" }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "25%" }}
                  >
                    <PlusOutlined /> Issue stationary
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Card>

      <Card className="gx-card" style={{ width: "100%" }}>
        <Button
          type="primary"
          htmlType="submit"
          className="gx-mb-3 gx-btn-blue"
        >
          {" "}
          Update{" "}
        </Button>
      </Card>
      <NotificationContainer />
    </Form>
	:
	<div>
		<Spin />
	</div>
	
  );
};

export default StudentStockFormEdit;
