import React, { useEffect, useState } from 'react';
import {
	Form,
	Input,
	Tooltip,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	Card,
	AutoComplete,
	Popconfirm,
	Tag,
	Modal,
	DatePicker
  } from "antd";
  import moment from "moment";
import { Table } from "ant-table-extensions";
import { useFormDetail } from '../../../util/dataProvider';
import { useHistory } from "react-router-dom";
import {
	NotificationContainer,
	NotificationManager,
  } from "react-notifications";

const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;
const formItemLayout = {
	labelCol: {
	  xs: { span: 24 },
	  sm: { span: 8 },
	},
	wrapperCol: {
	  xs: { span: 24 },
	  sm: { span: 16 },
	},
  };
  const tailFormItemLayout = {
	wrapperCol: {
	  xs: {
		span: 24,
		offset: 0,
	  },
	  sm: {
		span: 16,
		offset: 8,
	  },
	},
  };
const StockList = () => {
	const [form] = Form.useForm();
	const [editform] = Form.useForm();
	const { loading, success, setSuccess, getStockIssueList, stockIssueList,studentList,getStudentList,classList,getClassList,createStudent,getDeleteStudent,updateStudent } = useFormDetail();
	let history = useHistory();
	const [visible, setVisible] = useState(false);
    const [editvisible, setEditvisible] = useState(false);
	const [editDetails, setEditDetails] = useState('');
	
	const [showMessage, setShowMessage] = useState('');
	useEffect(() => {
		getClassList();
		getStudentList();
	}, []);

	useEffect(() => {
		if (success) {
			//alert('testing')
		  //form.resetFields();
		  NotificationManager.success(showMessage, "Success");
	
		  setSuccess(false);
		  form.resetFields();
		  setTimeout(() => {
			getStudentList();
			setVisible(false);
			setEditvisible(false);
			NotificationManager.listNotify.forEach((n) =>
			  NotificationManager.remove({ id: n.id })
			);
			history.push("/main/students");
			setShowMessage("");
		  }, 1000);
		}
	  }, [success]);

	const handleOk = () => {
		// this.setState({
		//   ModalText: 'The modal will be closed after two seconds',
		//   confirmLoading: true,
		// });
		setTimeout(() => {
		  setVisible(false);
		}, 2000);
	  };
	  const handleCancel = () => {
		editform.resetFields();
		//setEditBook(null);
		console.log("Clicked cancel button");
		setVisible(false);
		setEditvisible(false);
	   
	   
	  };
	 
	
	  const onFinish = (values) => {
		// alert(JSON.stringify(values));
		console.log("Received values of form: ", values);
		createStudent(values);
		setShowMessage("Student added Successfully");
	  };
	  const onFinishEdit = (values) => {
		//alert(JSON.stringify(values));
		//console.log("Received values of form: ", editBook.id);
		values.id = editDetails.id;
		values.classId=values.classId && values.classId.key?values.classId.key:values.classId;
		//typeof(values.classId) === "object" ? (values.classId = values.classId.key) : values.classId;
		//setEditBook(values);
		//alert(values.id) 
		//alert(JSON.stringify(values));
		updateStudent(values);
		setShowMessage("Student data updated successfully !!");
		setEditvisible(false);
	  };
	  function handleChange(value) {
		// getSubjectListByClass({classId:value})
		console.log(`selected ${value}`);
	  }
	
	  function handleBlur() {
		console.log("blur");
	  }
	
	  function handleFocus() {
		console.log("focus");
	  }	

	const columns = [
		{ title: 'Student Name', dataIndex: 'student_name', key: 'student_name' ,
		render: text => <span style={{color:'#038fdd'}}>{text}</span>},
		{ title: 'Add. Number', dataIndex: 'admission_number', key: 'adno' },
		{ title: 'Class', dataIndex: 'classname', key: 'class' },
		{ title: 'Section', dataIndex: 'section_name', key: 'section' },
		{ title: 'Academic', dataIndex: 'acadamic_year', key: 'acmyear' },
		{ title: 'Gender', dataIndex: 'gender', key: 'gender' },
		{ title: 'Mobile', dataIndex: 'mobile_number', key: 'mobile_number' },
		{title:'Action',
	  dataIndex: 'Id',
	  key:'Id',
	  render: (text, record) => (
		   <span>
			 <span className="gx-link">
			<Tag  onClick={()=>{
				setEditDetails(record)
				setEditvisible(true);
				editform.setFieldsValue({
					student_name: record.student_name,
					father_name: record.father_name,
					classId: {key:record.classId,label:record.classname},
					section_name: record.section_name,  
					gender: record.gender,  
					admission_number: record.admission_number, 
					mobile_number: record.mobile_number, 
					acadamic_year: record.acadamic_year, 
					dob: moment(record.dob, 'YYYY-MM-DD'),
					
					id: record.id,
	
	
				   });
					//history.push("/main/edit-book");
			
				
				//history.push('/main/class-edit')
			}} color="#038fdd">Edit</Tag>
			</span>
			<span className="gx-link">
			<Popconfirm title="Are you sure want to delete this Student?" onConfirm={()=>onDelete(record.id)}  okText="Yes"
			cancelText="No" okButtonProps={{type: 'default',style:{backgroundColor:'#A2A08A4D',color:'#000'}}}  cancelButtonProps={{type: 'default',style:{backgroundColor:'red',color:'#fff'}}}>
			<Tag color="#f50">Delete</Tag>
			</Popconfirm>
			</span>
	
			</span>
	   
		
	  )}
		//{ title: 'Fees Status', dataIndex: 'paid_status', key: 'status' },
		//{ title: 'Issue date', dataIndex: 'createdDate', key: 'date' },
	// 	{title: 'Download', dataIndex: 'id', key: 'id', render: () => <span onClick={()=>MyDocument()} className="gx-link">
	
	// Export Pdf
	// 	</span>},
	];
	const onDelete = (id) => {
		getDeleteStudent({id:id});
		setShowMessage("Student Deleted Successfully");
	  }
	return (
		<div>
		<Card title="Students List"
		extra={<Button type="primary" onClick={() => setVisible(true)}>Add Student</Button>} 
		   >
			<Table
				className="gx-table-responsive"
				columns={columns}
				searchable={true}
				bordered={true}
				//expandedRowRender={(record) => expandData(record)}
				dataSource={studentList}
			/>
			<NotificationContainer />
		</Card>
		<Modal
        title="Add Student"
        visible={visible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="bookRegister"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="classId"
            label={<span>Select Class &nbsp;</span>}
            // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
          >
            <Select
              showSearch
              //style={{width: 200}}
              name="classId"
              placeholder="Select a class"
              optionFilterProp="children"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {classList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.classname}
                </Option>
              ))}
            </Select>
          </Form.Item>

          

         
          <Form.Item name="student_name" label={<span>Student Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter student name."
								}
							]}>
								<Input placeholder="Student name" />
							</Form.Item>
							
							<Form.Item name="father_name" label={<span>Father's Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="Enter father name" />
							</Form.Item>
							<Form.Item name="section_name" label={<span>Section &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="Enter Section" />
							</Form.Item>
							<Form.Item name="gender" label={<span>Gender &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Gender">
									<Option value="Male">Male</Option>
									<Option value="Female">Female</Option>
								</Select>
							</Form.Item>

							<Form.Item name="admission_number" label={<span>Admission Number &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter admission no."
								}
							]}>
								<Input placeholder="Addmission No" />
			</Form.Item>

			<Form.Item name="dob" label={<span>Date of Birth &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter date of birth."
								}
							]}>
								<DatePicker className=" gx-w-100" />
			</Form.Item>
			<Form.Item name="acadamic_year" label={<span>Academic Year &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Year">
									<Option value="2022">2022-2023</Option>
									<Option value="2023">2023-2024</Option>
								</Select>
							</Form.Item>

          <Form.Item
            name="mobile_number"
            label={<span>Mobile Number &nbsp;</span>}
            // rules={[{ required: true, message: 'Please enter number of page', whitespace: true }]}
          >
            <Input
              type="number"
              name="mobile_number"
              placeholder={"Enter number mobile number"}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Add Student
            </Button>
          </Form.Item>
        </Form>
      </Modal>



	  <Modal
        title="Edit Student"
        visible={editvisible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={editform}
          name="bookRegister"
          onFinish={onFinishEdit}
          scrollToFirstError
        >
          <Form.Item
            name="classId"
            label={<span>Select Class &nbsp;</span>}
            // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
          >
            <Select
              showSearch
              //style={{width: 200}}
              name="classId"
              placeholder="Select a class"
              optionFilterProp="children"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {classList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.classname}
                </Option>
              ))}
            </Select>
          </Form.Item>

          

         
          <Form.Item name="student_name" label={<span>Student Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter student name."
								}
							]}>
								<Input placeholder="Student name" />
							</Form.Item>
							
							<Form.Item name="father_name" label={<span>Father's Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="Enter father name" />
							</Form.Item>
							<Form.Item name="section_name" label={<span>Section &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="Enter Section" />
							</Form.Item>
							<Form.Item name="gender" label={<span>Gender &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Gender">
									<Option value="Male">Male</Option>
									<Option value="Female">Female</Option>
								</Select>
							</Form.Item>

							<Form.Item name="admission_number" label={<span>Admission Number &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter admission no."
								}
							]}>
								<Input placeholder="Addmission No" />
			</Form.Item>

			<Form.Item name="dob" label={<span>Date of Birth &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter date of birth."
								}
							]}>
								<DatePicker className=" gx-w-100" value={moment(editDetails.dob, 'YYYY-MM-DD')} />
			</Form.Item>
			<Form.Item name="acadamic_year" label={<span>Academic Year &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Year">
									<Option value="2022">2022-2023</Option>
									<Option value="2023">2023-2024</Option>
								</Select>
							</Form.Item>

          <Form.Item
            name="mobile_number"
            label={<span>Mobile Number &nbsp;</span>}
            // rules={[{ required: true, message: 'Please enter number of page', whitespace: true }]}
          >
            <Input
              type="number"
              name="mobile_number"
              placeholder={"Enter number mobile number"}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Save Student
            </Button>
          </Form.Item>
        </Form>
      </Modal>

	  </div>
	);
};

export default StockList;
