import React, { useEffect, useState } from 'react';
import { useFormDetail } from '../../../util/dataProvider';
import { useHistory } from 'react-router-dom';
import {
	Button,
	Card,
	Popconfirm,
	Tag,
	Modal,
	Table
  } from "antd";

import { PDFExport } from '@progress/kendo-react-pdf';

const VisitorsRecord = () => {
	const { loading, success, setSuccess, getVisitorList, visitorList, getDeleteVisitor } = useFormDetail();
	const [ isModalVisible, setIsModalVisible ] = useState(false);
	const [ modalData, setModalData ] = useState(undefined);

	const pdfExportComponent = React.useRef(null);
	let history = useHistory();

	useEffect(() => {
		getVisitorList();
		console.log('visitorList', visitorList);
	}, []);

	const onDelete = (id) => {
		getDeleteVisitor({ id: id });
		//setShowMessage("Record Deleted Successfully !!");
	};

	const columns = [
		{ title: 'Date', dataIndex: 'appointment_date', key: 'appointment_date' },
		{ title: 'Visitor Name', dataIndex: 'name', key: 'name' },
		{ title: 'Reason', dataIndex: 'reason', key: 'reason' },
		{ title: 'Is Appointment', dataIndex: 'appointment', key: 'appointment' },
		{ title: 'Check-In Time', dataIndex: 'checkin', key: 'checkin' },
		{ title: 'Check-Out Time', dataIndex: 'checkout', key: 'checkout' },
		{
			title: "Action",
			dataIndex: "id",
			key: "id",
			render: (text, record) => (
			  <span>
				{/* <span className="gx-link">
				  <Tag
	  
					onClick={() => {
					  getBookListByClass({ classId: record.classId,category:"notebook" });
					 setEditvisible(true);
					 setEditBook(record);
					 editform.setFieldsValue({
					  name: record.name,
					  numberofpage: record.numberofpage,
					  classId: {key:record.classId,label:record.classname},
					  subjectId: record.subjectId, 
					  bookId: {key:record.bookId,label:record.name},  
					  credit: record.credit,
	  
					 });
					  //history.push("/main/edit-book");
					}}
					color="#038fdd"
				  >
					Edit
				  </Tag>
				</span> */}
				<span className="gx-link">
				  <Popconfirm
					title="Are you sure want to delete this record?"
					onConfirm={() => onDelete(record.id)}
					okText="Yes"
					cancelText="No"
					okButtonProps={{
					  type: "default",
					  style: { backgroundColor: "#A2A08A4D", color: "#000" },
					}}
					cancelButtonProps={{
					  type: "default",
					  style: { backgroundColor: "red", color: "#fff" },
					}}
				  >
					<Tag color="#f50">Delete</Tag>
				  </Popconfirm>
				</span>
			  </span>
			),
		  },
	];


	return (
		<Card
			title="Visitors List"
			/*extra={
				<Button
					type="primary"
					onClick={() => {
						//history.push('/main/stock-issue');
					}}
				>
					Issue Stock
				</Button>
			}*/
		>
			

		<Table
          className="gx-table-responsive"
          bordered
          columns={columns}
          dataSource={visitorList}
          searchable
        />

			<Modal
				visible={isModalVisible}
				title={'Visitor List'}
				closable={true}
				onCancel={() => setIsModalVisible(false)}
				footer={false}
				bodyStyle={{
					maxHeight: '80vh',
					overflowY: 'scroll'
				}}
				width={1000}
			>
				<button
					className="k-button"
					onClick={() => {
						if (pdfExportComponent.current) {
							pdfExportComponent.current.save();
						}
					}}
				>
					Export PDF
				</button>

				<PDFExport paperSize="A4" ref={pdfExportComponent}>
					<div style={{ width: '100%', padding: 20 }}>
						<h1 style={{ marginBottom:10,marginTop:30,textAlign:'center', width:800}}>St. Joseph's Matric Hr. Sec. School, Maraimalai Nagar</h1>
						<p style={{ fontSize: 18, textAlign:'center',marginTop:0, width:700 }}>Note Book Check List 2022-2023</p>
					
					
						<div className="gx-flex-row" style={{ justifyContent: 'space-between', marginRight: 20 }}>
							<p style={{ fontSize: 16 }}>Class: {modalData && modalData[0] && modalData[0].classname} </p>
							<p style={{ fontSize: 16 }}>Name: {modalData && modalData[0] && modalData[0].student_name} </p>
						</div>
						<table border="1" width="100%">
							<thead>
								<tr>
									<th>Subject</th>
									<th colSpan={6}>NoteBooks</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td />
									<td>Types</td>
									<td>No.of Pages</td>
									<td>Total No.</td>
									<td>Details</td>
									<td>Term</td>
									<td>Issued / Due</td>
								</tr>

								{modalData &&
									modalData.map((item, index) => {
										if (item.bookArray && item.bookArray.length > 0) {
											return item.bookArray.map((book, i) => {
												return (
													<tr>
														<td>{book.subjectname}</td>
														<td>Books</td>
														<td>80</td>
														<td>{book.qty}</td>
														<td>Test Note</td>
														<td>I, II</td>
														<td>{item.createdDate}</td>
													</tr>
												);
											});
										}
									})}
								{modalData &&
											modalData.map((item, index) => {
										if (item.notebookArray && item.notebookArray.length > 0) {
											return item.notebookArray.map((note, i) => {
												return (
													<tr>
														<td>{note.subjectname}</td>
														<td>Note Book</td>
														<td>80</td>
														<td>{note.qty}</td>
														<td>Test Note</td>
														<td>I, II</td>
														<td>{item.createdDate}</td>
													</tr>
												);
											});
										}
									})}
							</tbody>
							<tfoot>
								<tr>
									<td>Total :</td>
									<td colspan="3" />
									<td colspan="3" />
								</tr>
							</tfoot>
						</table>

						<div className="gx-flex-row" style={{ justifyContent: 'space-between', marginTop:50 }}>
							<p style={{ fontSize: 16 }}>Parent Signature  </p>
							<p style={{ fontSize: 16 }}>Class Teacher Signature </p>
						</div>
					</div>
				</PDFExport>
			</Modal>
		</Card>
	);
};

export default VisitorsRecord;
