import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete, Popconfirm, Tag, Popover,
  List
} from 'antd';
import { Table } from "ant-table-extensions";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import { NotificationContainer, NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { push } from 'connected-react-router';
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: record => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};



const Registration = () => {
  const [form] = Form.useForm();
  let history = useHistory();

  const { loading, createClass, success, setSuccess, getBookList, bookList, classWiseList, getClassWiseList, bookStockList, getDeleteAssign, setEditStocklist, stockrecord } = useFormDetail();
  const onDelete = (id) => {
    getDeleteAssign({ id: id })
  }
  const columns = [

    {
      title: 'Class',
      dataIndex: 'classname',
    },

    {
      title: 'Total Text Book',
      dataIndex: 'booksCount',
      render: (text, record) => <Popover content={() => showData(record.bookArray)} title='Book Deatils'>
        <Button type="primary">{text}</Button>
      </Popover>
    },
    {
      title: 'Total Note Book',
      dataIndex: 'notebooksCount',
      render: (text, record) => <Popover content={() => showData(record.notebookArray)} title='Note Book Details'>
        <Button type="primary">{text}</Button>
      </Popover>
    },
    {
      title: 'Total Stationery',
      dataIndex: 'stationeryCount',
      render: (text, record) => <Popover content={() => showStationeryData(record.stationeryArray)} title='Stationery Details'>
        <Button type="primary">{text}</Button>
      </Popover>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <span>
          <span className="gx-link" onClick={() => { setEditStocklist(record.id);  history.push('/main/class-assign-edit');  }}>
            <Tag color="#038fdd">Edit</Tag>
          </span>
          <Popconfirm title="Are you sure want to delete this Assign?" onConfirm={() => onDelete(record.id)} okText="Yes"
            cancelText="No" okButtonProps={{ type: 'default', style: { backgroundColor: '#A2A08A4D', color: '#000' } }} cancelButtonProps={{ type: 'default', style: { backgroundColor: 'red', color: '#fff' } }}>
            <Tag color="#f50">Delete</Tag>
          </Popconfirm>
        </span>
      )
    }
  ];
  useEffect(() => {

    if (success) {
      NotificationManager.success(success, 'Success', 3000);
      setSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    setEditStocklist(false);
    getClassWiseList();
  }, []);

  const showData = (values) => {
    const subcolumns = [

      {
        title: 'Subject',
        dataIndex: 'subjectname',
      },
      {
        title: 'Book Name',
        dataIndex: 'bookname',
      },

      {
        title: 'Quantity',
        dataIndex: 'qty',
      },]
    return (
      <div>
        <Table scroll={{ x:100,y:300}} pagination={{ pageSize: 5}} className="gx-table-responsive" bordered columns={subcolumns} dataSource={values}/>
        {/* <List>
			 {values.map((item, index) =>
			 <List.Item key={index}>
				 <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
				 <p>{item.subjectname}</p>
          <p>{item.bookname}</p>
				 <p>{item.qty}</p>

				 </div>
			 </List.Item>
			 )}
		 </List> */}
      </div>
    )
  };
  const showStationeryData = (values) => {
    const subcolumns = [ 
      {
        title: 'Name',
        dataIndex: 'name',
      },

      {
        title: 'Quantity',
        dataIndex: 'qty',
      },]
    return (
      <div>
        <Table scroll={{ x:100,y:300}} pagination={{ pageSize: 5}} className="gx-table-responsive" bordered columns={subcolumns} dataSource={values} />
      </div>
    )
  }

  return (
    <div>
      <Card title="Class Wise Assign List"
      >
        <Table className="gx-table-responsive" bordered columns={columns} dataSource={classWiseList} searchable
          exportableProps={{
            showColumnPicker: false, fileName: "Book_Reports", btnProps: {
              type: "primary",
              icon: false,
              children: <span style={{ color: '#000' }}> Export to CSV</span>,
            }
          }}
        />

        <NotificationContainer />
      </Card>
    </div>
  );
};

export default Registration;

