import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,
  Popconfirm,
  Tag,
  Modal,
} from "antd";
import { Table } from "ant-table-extensions";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useFormDetail } from "../../../util/dataProvider";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Registration = () => {
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  let history = useHistory();
  const [visible, setVisible] = useState(false);
  const [editvisible, setEditvisible] = useState(false);

  const {
    loading,
    success,
    setSuccess,
    getBookList,
    bookList,
    getDeleteCat,
    getSubjectListByClass,
    createCat,
    classList,
    subjectListByClass,
    getClassList,
    getBookListByClass,
    bookListByClass,
    editCat
  } = useFormDetail();
  const [showMessage, setShowMessage] = useState('');
  const [editBook, setEditBook] = useState(null);
  const columns = [
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   render: text => <span style={{color:'#038fdd'}} >{text=='book'?'Text Book':'Note Book'}</span>,
    // },
    {
      title: "Stationery Name",
      dataIndex: "name",
      render: (text) => <span style={{ color: "#038fdd" }}>{text}</span>,
    },
   
    ,
    {
      title: "In Stock",
      dataIndex: "credit",
    },
    // ,{
    //   title: 'No of pages',
    //   dataIndex: 'numberofpage',
    // }
    // ,{
    //   title: 'Amount',
    //   dataIndex: 'amount',
    // }
    {
      title: "Date",
      dataIndex: "createdDate",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <span>
          <span className="gx-link">
            <Tag

              onClick={() => {
               setEditvisible(true);
               setEditBook(record);
               editform.setFieldsValue({
                bookId: {key:record.bookId,label:record.name},  
                credit: record.credit

               });
                //history.push("/main/edit-book");
              }}
              color="#038fdd"
            >
              Edit
            </Tag>
          </span>
          <span className="gx-link">
            <Popconfirm
              title="Are you sure want to delete this stock?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                type: "default",
                style: { backgroundColor: "#A2A08A4D", color: "#000" },
              }}
              cancelButtonProps={{
                type: "default",
                style: { backgroundColor: "red", color: "#fff" },
              }}
            >
              <Tag color="#f50">Delete</Tag>
            </Popconfirm>
          </span>
        </span>
      ),
    },
  ];
 
  useEffect(() => {
    getClassList();
    getSubjectListByClass();
    getBookList({ type: "stationery" });
    getBookListByClass({ classId: null,category:"stationery" });
  }, []);
  const onDelete = (id) => {
    getDeleteCat({ id: id });
    setShowMessage("Stock Deleted Successfully !!");
  };

  useEffect(() => {
    if (success) {
      //form.resetFields();
      getBookList({ type: "stationery" });
      NotificationManager.success(showMessage, "Success");

      setSuccess(false);
      form.resetFields();
      setTimeout(() => {
        
        setVisible(false);
        setEditvisible(false);
        NotificationManager.listNotify.forEach((n) =>
          NotificationManager.remove({ id: n.id })
        );
       // history.push("/main/books-type");
        setShowMessage("");
      }, 1000);
    }
  }, [success]);

  const onFinish = (values) => {
    // alert(JSON.stringify(values));
    console.log("Received values of form: ", values);
    values.category = "stationery";
    createCat(values);
    setShowMessage("Stock Added Successfully");
  };
  const onFinishEdit = (values) => {
    // alert(JSON.stringify(values));
    console.log("Received values of form: ", editBook.id);
    values.id = editBook.id;
    values.bookId = values.bookId && values.bookId.key
        ? values.bookId.key
        : values.bookId;
    //typeof(values.classId) === "object" ? (values.classId = values.classId.key) : values.classId;
    //setEditBook(values);
    //alert(values.classId)
    editCat(values, editBook.id);
    setShowMessage("Stock Updated Successfully");
  };
  function handleChange(value) {
    // getSubjectListByClass({classId:value})
    console.log(`selected ${value}`);
  }
  function handleChangeClass(value) {
    getBookListByClass({ classId: value,category:"stationery" });
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log("blur");
  }

  function handleFocus() {
    console.log("focus");
  }
  const handleOk = () => {
    // this.setState({
    //   ModalText: 'The modal will be closed after two seconds',
    //   confirmLoading: true,
    // });
    setTimeout(() => {
      setVisible(false);
    }, 2000);
  };
  const handleCancel = () => {
    editform.resetFields();
    setEditBook(null);
    console.log("Clicked cancel button");
    setVisible(false);
    setEditvisible(false);
  };

  return (
    <div>
      <Card
        title="Stationery Stock List"
        extra={
          <Button type="primary" onClick={() => setVisible(true)}>
            Add Stationery Stock
          </Button>
        }
      >
        <Table
          className="gx-table-responsive"
          bordered
          columns={columns}
          dataSource={bookList}
          searchable
        />

        <NotificationContainer />
      </Card>

      <Modal
        title="Add Stationery Stock"
        visible={visible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="bookRegister"
          onFinish={onFinish}
          scrollToFirstError
        >
        

        
          <Form.Item
            name="bookId"
            label={<span>Select Stationery &nbsp;</span>}
            // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
          >
            <Select
              showSearch
              //style={{width: 200}}
              name="bookId"
              placeholder="Select a book"
              optionFilterProp="children"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {bookListByClass.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

         

          <Form.Item
            name="credit"
            placeholder="Number of book"
            label={
              <span>
                Number Of Stock &nbsp; 
              </span>
            }
            rules={[{ required: true, message: 'Please enter number of book', whitespace: true }]}
      >
       <Input type="number" name="credit" placeholder={'Enter number of books'} />
        </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Stationery Stock"
        visible={editvisible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={editform}
          name="editBookRegister"
          onFinish={onFinishEdit}
          scrollToFirstError
        >
       
         
          <Form.Item
            name="bookId"
            label={<span>Select Book &nbsp;</span>}
            // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
          >
            <Select
              showSearch
              //style={{width: 200}}
              name="bookId"
              placeholder="Select a book"
              optionFilterProp="children"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {bookListByClass.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="credit"
            placeholder="Number of book"
            label={
              <span>
                Number Of Stock &nbsp; 
              </span>
            }
            rules={[{ required: true, message: 'Please enter number of book', whitespace: true }]}
      >
       <Input type="number" name="credit" placeholder={'Enter number of books'} />
        </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Registration;
