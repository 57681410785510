import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Modal } from 'antd';
import { useFormDetail } from '../../../util/dataProvider';
import { useHistory } from 'react-router-dom';
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";

import { PDFExport } from '@progress/kendo-react-pdf';

const StockList = () => {
	const { loading, success, setSuccess, getStockIssueList, stockIssueList } = useFormDetail();
	const [ isModalVisible, setIsModalVisible ] = useState(false);
	const [ modalData, setModalData ] = useState(undefined);
	const [ activeExpRow, setActiveExpRow ]=useState(null);
	const pdfExportComponent = React.useRef(null);

	let history = useHistory();
	useEffect(() => {
		getStockIssueList();

		console.log('stockIssueList', stockIssueList);
	}, []);

	

	const columns = [
		{ title: 'Student Name', dataIndex: 'student_name', key: 'name' },
		{ title: 'Addmission number', dataIndex: 'admission_number', key: 'adno' },
		{ title: 'Class', dataIndex: 'classname', key: 'class' },
		{ title: 'Section', dataIndex: 'section_name', key: 'section' },
		{ title: 'Book issued', dataIndex: 'book_issued', key: 'book_issued' },
		{ title: 'Note Book issued', dataIndex: 'notebook_issued', key: 'notebook_issued' },
		{ title: 'Book pending', dataIndex: 'remaining_book_issued', key: 'remaining_book_issued' },
		{ title: 'Note Book pending', dataIndex: 'remaining_notebook_issued', key: 'remaining_notebook_issued' },
		{ title: 'Academic year', dataIndex: 'acadamic_year', key: 'acmyear' },
		{ title: 'Fees Status', dataIndex: 'paid_status', key: 'status' },
		
		{
			title: 'View',
			dataIndex: 'id',
			key: 'id',
			render: (e) => (
				<a
					onClick={() => {
						showModalPdf(e);
					}}
					className="gx-link"
				>
					Export Pdf
				</a>
			)
		},
		{ title: 'Issue date', dataIndex: 'createdDate', key: 'date' }
	];

	const showModalPdf = (e) => {
		if (stockIssueList) {
			const data = stockIssueList.filter((item) => item.id == e);
			setModalData([ data[0] ]);
			setIsModalVisible(true);
			console.log(data[0]);
		}
	};

	const expandData = (data, index) => {
		//console.log('index', index);
		return ( activeExpRow == data.id &&
			<div key={data.id} className="gx-flex-row ">
				{data.bookArray &&
				data.bookArray.length > 0 && (
					<Card title="Issued Books" style={{ marginRight: 15, paddingLeft: 15 }}>
						<div className="gx-flex-row">
							<p style={{ width: 90, color: '#038fde' }}>Subject</p>
							<p style={{ width: 90, color: '#038fde' }}>Books</p>
							<p style={{ width: 60, color: '#038fde' }}>Qty</p>
						</div>
						{data.bookArray.map((book) => {
							return (
								<div className="gx-flex-row">
									<p style={{ width: 90 }}>{book.subjectname}</p>
									<p style={{ width: 90 }}>{book.bookname}</p>
									<p style={{ width: 60 }}>{book.qty}</p>
								</div>
							);
						})}
					</Card>
				)}

				{data.notebookArray &&
				data.notebookArray.length > 0 && (
					<Card title="Issued Note Books" style={{ marginRight: 15, paddingLeft: 15 }}>
						<div className="gx-flex-row">
							<p style={{ width: 90, color: '#038fde' }}>Subject</p>
							<p style={{ width: 90, color: '#038fde' }}>NoteBooks</p>
							<p style={{ width: 60, color: '#038fde' }}>Qty</p>
						</div>
						{data.notebookArray.map((notebook) => {
							return (
								<div className="gx-flex-row">
									<p style={{ width: 90 }}>{notebook.subjectname}</p>
									<p style={{ width: 90 }}>{notebook.bookname}</p>
									<p style={{ width: 60 }}>{notebook.qty}</p>
								</div>
							);
						})}
					</Card>
				)}

				{data.stationeryArray &&
				data.stationeryArray.length > 0 && (
					<Card title="Issued Stationary" style={{ marginRight: 15, paddingLeft: 15 }}>
						<div className="gx-flex-row">
							<p style={{ width: 90, color: '#038fde' }}>Name</p>
							<p style={{ width: 50, color: '#038fde' }}>Qty</p>
						</div>
						{data.stationeryArray.map((book) => {
							return (
								<div className="gx-flex-row">
									<p style={{ width: 90 }}>{book.name}</p>
									<p style={{ width: 50 }}>{book.qty}</p>
								</div>
							);
						})}
					</Card>
				)}
			</div>
		);
	};

	return (
		<Card
			title="Student Stock List"
			extra={
				<Button
					type="primary"
					onClick={() => {
						history.push('/main/stock-issue');
					}}
				>
					Issue Stock
				</Button>
			}
		>
			<Table
				//key={stockIssueList.id}
				className="gx-table-responsive"
				columns={columns}
				dataSource={stockIssueList}
				//expandedRowRender={(record, index) => expandData(record, index)}
				// expandable={{
				// 	expandedRowRender: (record, index) => expandData(record, index),
				// 	onExpand: (expanded, record) => {
				// 		const keys = [];
				// 		if (expanded) {
				// 		   keys.push(record.id); 
				// 		}
					
				// 		setActiveExpRow(keys);
				// 	},
				// 	expandIcon: ({ expanded, onExpand, record }) =>
				// 	expanded ? (
				// 	<MinusCircleTwoTone onClick={e => onExpand(record, e)} />
				// 	) : (
				// 	<PlusCircleTwoTone onClick={e => onExpand(record, e)} />
				// 	)
					
				// }}
			/>

			<Modal
				visible={isModalVisible}
				title={'Student Stock List'}
				closable={true}
				onCancel={() => setIsModalVisible(false)}
				footer={false}
				bodyStyle={{
					maxHeight: '80vh',
					overflowY: 'scroll'
				}}
				width={1000}
			>
				<button
					className="k-button"
					onClick={() => {
						if (pdfExportComponent.current) {
							pdfExportComponent.current.save();
						}
					}}
				>
					Export PDF
				</button>

				<PDFExport paperSize="A4" ref={pdfExportComponent}>
					<div style={{ width: '100%', padding: 20 }}>
						<h1 style={{ marginBottom:10,marginTop:30,textAlign:'center', width:800}}>St. Joseph's Matric Hr. Sec. School, Maraimalai Nagar</h1>
						<p style={{ fontSize: 18, textAlign:'center',marginTop:0, width:700 }}>Note Book Check List 2022-2023</p>
					
					
						<div className="gx-flex-row" style={{ justifyContent: 'space-between', marginRight: 20 }}>
							<p style={{ fontSize: 16 }}>Class: {modalData && modalData[0] && modalData[0].classname} </p>
							<p style={{ fontSize: 16 }}>Name: {modalData && modalData[0] && modalData[0].student_name} </p>
						</div>
						<table border="1" width="100%">
							<thead>
								<tr>
									<th colSpan={12}>Books</th>
								</tr>
							</thead>
							<tbody>
							<tr>
									<td>S.NO</td>
									<td>Subject</td>
									<td>Types</td>
									<td>No.of Pages</td>
									<td>Total No.</td>
									<td>Issued / Due</td>
								</tr>

								{
								
								modalData &&
									modalData.map((item, index) => {
										
										if (item.bookArray && item.bookArray.length > 0) {
											
											return item.bookArray.map((book, i) => {
											
												return (
													<tr>
														<td>{i+1}</td>
														<td>{book.subjectname}</td>
														<td>{book.bookname}</td>
														<td>{book.pageno}</td>
														<td>{book.qty}</td>
														<td>{item.createdDate}</td>
													</tr>
												);
											});
										}
									})}
								
							</tbody>
							<tfoot>
								<tr>
									<td>Total :</td>
									<td colspan="3" />
									<td colspan="3" />
								</tr>
							</tfoot>
						</table>
						<p style={{ fontSize: 16,marginTop:10,textAlign:'center',fontWeight:'bold' }}>Note Book Details </p>
						<table border="1" width="100%">
							<thead>
								<tr>
								
									<th colSpan={6}>Note Books</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>S.NO</td>
									<td>Subject</td>
									<td>Types</td>
									<td>No.of Pages</td>
									<td>Total No.</td>
									<td>Issued / Due</td>
								</tr>

								
								{modalData &&
											modalData.map((item, index) => {
										if (item.notebookArray && item.notebookArray.length > 0) {
											return item.notebookArray.map((note, i) => {
												return (
													<tr>
														<td>{i+1}</td>
														<td>{note.subjectname}</td>
														<td>{note.bookname}</td>
														<td>{note.pageno}</td>
														<td>{note.qty}</td>
														<td>{item.createdDate}</td>
													</tr>
												);
											});
										}
									})}
							</tbody>
							<tfoot>
								<tr>
									<td>Total :</td>
									<td colspan="3" />
									<td colspan="3" />
								</tr>
							</tfoot>
						</table>

						<p style={{ fontSize: 16,marginTop:10,textAlign:'center',fontWeight:'bold' }}>Stationery Details </p>
						<table border="1" width="100%">
							<thead>
								<tr>
									<th>S.NO</th>
									<th colSpan={6}>Stationary</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td />
									<td>Types</td>
									<td>Total No.</td>
								
									{/* <td>Term</td> */}
									<td>Issued / Due</td>
								</tr>

							
								{modalData &&
											modalData.map((item, index) => {
										if (item.stationeryArray && item.stationeryArray.length > 0) {
											return item.stationeryArray.map((note, i) => {
												return (
													<tr>
														<td>{i+1}</td>
														<td>{note.name}</td>
														<td>{note.qty}</td>
														<td>{item.createdDate}</td>
													</tr>
												);
											});
										}
									})}
							</tbody>
							<tfoot>
								<tr>
									<td>Total :</td>
									<td colspan="3" />
									<td colspan="3" />
								</tr>
							</tfoot>
						</table>
						<div className="gx-flex-row" style={{ justifyContent: 'space-between', marginTop:50 }}>
							<p style={{ fontSize: 16 }}>Parent Signature  </p>
							<p style={{ fontSize: 16 }}>Class Teacher Signature </p>
						</div>
					</div>
				</PDFExport>
			</Modal>
		</Card>
	);
};

export default StockList;
