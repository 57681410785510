import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card, Form, Input, Select, DatePicker } from 'antd';

import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import { useFormDetail } from '../../../util/dataProvider';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {NotificationContainer, NotificationManager} from "react-notifications";

const FormItem = Form.Item;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

const StudentStockForm = () => {
	const [form] = Form.useForm();
	const {
		loading,
		createSubject,
		success,
		setSuccess,
		getClassList,
		classList,
		createCat,
		getSubjectListByClass,
		subjectListByClass,
		createStudent,
		getStationeryList,
		stationeryList,
		studentList,getStudentList
	} = useFormDetail();

	useEffect(() => {
		getClassList();
		getStationeryList();
		
	}, []);

	const onFinish = (values) => {
		console.log('Received values of form: ', values);
		createStudent(values);
	};

	useEffect (() => {
    
		if(success){
		 form.resetFields();
		 NotificationManager.success('Stock added Successfully', 'Success', 2000);
		 setSuccess(false);
		}
	   }, [success]);

	const handleChangeClass = (value) => {
		
		getSubjectListByClass({ classId: value });
	};

	
	return (
		
		<Form
			form={form}
			autoComplete="off"
			//initialValues={{ remember: true }}
			name="basic"
			onFinish={onFinish}
			//onFinishFailed={this.onFinishFailed}
			layout="inline"
			
		>
		
				

				<Card className="gx-card" title="Stutdent info" style={{width:'100%'}}>


				<Row>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem
								 name="classId"
								 rules={[
									{
										required: true,
										message: "Please choose class."
									}
								]}
								label={<span>Select Class &nbsp;</span>}
							>
								<Select
									showSearch
									//style={{ width: 250 }}
									placeholder="Select a class"
									optionFilterProp="children"
									onChange={handleChangeClass}
									//onFocus={handleFocus}
									//onBlur={handleBlur}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{classList &&
										classList.map((item) => (
											<Option key={item.id} value={item.id}>
												{item.classname}
											</Option>
										))}
								</Select>
							</FormItem>
						</Col>
						{/* <Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem
								 name="student" 
								label={<span>Student &nbsp;</span>}
								rules={[
									{
										required: false,
										message: "Please choose student."
									}
								]}
							>
								<Select
									showSearch
									style={{ width: 250 }}
									name="classId"
									placeholder="Select a student"
									optionFilterProp="children"
									//onFocus={handleFocus}
									//onBlur={handleBlur}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								/>
							</FormItem>
						</Col> */}
					</Row>

					<Row>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem name="student_name" label={<span>Student Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter student name."
								}
							]}>
								<Input placeholder="Student name" />
							</FormItem>
							<FormItem name="admission_number" label={<span>Admission. No &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter admission no."
								}
							]}>
								<Input placeholder="Addmission No" />
							</FormItem>
							<FormItem name="dob" label={<span>Date of Birth &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter date of birth."
								}
							]}>
								<DatePicker className=" gx-w-100" />
							</FormItem>
							<FormItem name="father_name" label={<span>Father's Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="" />
							</FormItem>
						</Col>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem name="acadamic_year" label={<span>Academic Year &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Year">
									<Option value="2022">2022-2023</Option>
									<Option value="2023">2023-2024</Option>
								</Select>
							</FormItem>
							<FormItem name="section_name" label={<span>Section&nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter section."
								}
							]}>
								<Input placeholder="Section" />
							</FormItem>
							<FormItem name="mobile_number" label={<span>Mobile no. &nbsp;</span>}>
								<Input placeholder="" 
								//onChange={onChangeMobile}
								 />
							</FormItem>

							<FormItem name="paid_status" label={<span>Status &nbsp;</span>}
							rules={[
								{
									required: false,
									message: "Please select status."
								}
							]}>
								<Select placeholder="Status" >
									<Option value="pending">Pending</Option>
									<Option value="paid">Paid</Option>
								</Select>
							</FormItem>
						</Col>
					</Row>
					<Button type="primary" htmlType="submit" className="gx-mb-3 gx-btn-blue"> Submit </Button>	
				</Card>
		
				{/* Books formlist */}
				
			
				{/* Note formlist */}
				

				{/* Stationary formlist */}
			
			
			
				<NotificationContainer/>
	</Form>
	);
};

export default StudentStockForm;
