import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Table,Popconfirm,Tag
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;



 
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };



const Registration = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const {loading,createClass,success,setSuccess,getClassList,classList,getDeleteClass,setEditClass } = useFormDetail();
  const columns = [{
    title: 'Class name',
    dataIndex: 'classname',
    render: text => <span style={{color:'#038fdd'}}>{text}</span>,
  }, {
    title: 'Class code',
    dataIndex: 'classcode',
  }, {
    title: 'Class description',
    dataIndex: 'classdescription',
  },
  {title:'Action',
  dataIndex: 'Id',
  key:'Id',
  render: (text, record) => (
       <span>
         <span className="gx-link">
        <Tag  onClick={()=>{setEditClass(record)
                            history.push('/main/class-edit')
        }} color="#038fdd">Edit</Tag>
        </span>
        <Popconfirm title="Are you sure want to delete this Class?" onConfirm={()=>onDelete(record.id)}  okText="Yes"
        cancelText="No" okButtonProps={{type: 'default',style:{backgroundColor:'#A2A08A4D',color:'#000'}}}  cancelButtonProps={{type: 'default',style:{backgroundColor:'red',color:'#fff'}}}>
        <Tag color="#f50">Delete</Tag>
        </Popconfirm>
        

        </span>
   
    
  )}];
  useEffect (() => {
  
     if(success){
         NotificationManager.success(success, 'Success', 3000);
        setSuccess(false);
     }
  }, [success]);
  useEffect (() => {
    getClassList();
   }, []);
const onDelete = (id) => {
  getDeleteClass({id:id})
}
  return (
   <div>
      <Card title="Total Class List" 
      extra= {<Button type="primary"  onClick={()=>history.push('/main/class')}>Add Class</Button>}
      >
      <Table className="gx-table-responsive"
             columns={columns}
             dataSource={classList}
             bordered
             //title={() => 'Total Class list'}
             //footer={() => 'Footer'}
      />
       
        <NotificationContainer/>
      </Card>
  </div>
  );
};

export  default Registration;

