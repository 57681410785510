import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Popconfirm,Tag
} from 'antd';
import { Table} from "ant-table-extensions";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

 
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };



const Stationary = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  
  const {loading,success,setSuccess, bookStockList,getBookStock,} = useFormDetail();
  const columns = [
    {
      title: "Stationery",
      dataIndex: "name",
      render: (text) => <span style={{ color: "#038fdd" }}>{text}</span>,
    },
  {
    title: 'Current Stock',
    dataIndex: 'currentStock',
   // render: text => <span className="gx-link">{text}</span>,
  }
  ,{
    title: 'Total in Stock',
    dataIndex: 'inStock',
  }
  ,{
    title: 'Total out Stock',
    dataIndex: 'outStock',
  }
 
 ];
  useEffect (() => {
  
     if(success){
         NotificationManager.success(success, 'Success', 3000);
        setSuccess(false);
     }
  }, [success]);
  useEffect (() => {
    getBookStock({type:'stationery'});
   }, []);

  return (
   <div>
      <Card title="Stationery Stock Report" bordered={false}
      >
         <Table className="gx-table-responsive" bordered columns={columns} dataSource={bookStockList} searchable 
          exportableProps={{ showColumnPicker: false,fileName: "Note_Book_Reports",btnProps: {
               type: "primary",
               icon:false,
              children: <span style={{color:'#000'}}> Export to CSV</span>,
             }}}
         />
         
         <NotificationContainer/>
      </Card>
  </div>
  );
};

export  default Stationary;

