import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Table
  ,InputNumber
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import {NotificationContainer, NotificationManager} from "react-notifications";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

  
  // rowSelection object indicates the need for row selection
  

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Registration = () => {
  const [form] = Form.useForm();
  const {  loading,createSubject,success,setSuccess,getClassList,classList,createCat,getSubjectListByClass,subjectListByClass } = useFormDetail();

  useEffect (() => {
    
   if(success){
    //form.resetFields();
    NotificationManager.success('Book Saved Successfully', 'Success', 2000);
    setSuccess(false);
    form.resetFields();

   }
  }, [success]);

  useEffect (() => {
    getClassList()
   }, []);


  const onFinish = values => {
   // alert(JSON.stringify(values));
    console.log('Received values of form: ', values);
    values.category='notebook';
    createCat(values)
  };
  function handleChange(value) {
    getSubjectListByClass({classId:value})
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log('blur');
  }

  function handleFocus() {
    console.log('focus');
  }
  
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = value => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map(domain => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map(website => ({
    label: website,
    value: website,
  }));
  const Option = Select.Option;
  return (
      <div>
    <Card className="gx-card" title="Add Note Book">
   
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
     
      
     {/* <Form.Item
        name="category"
        label={
          <span>
            Select Category &nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please Select Category', whitespace: true }]}
      >
      
        <Select
        showSearch
        //style={{width: 200}}
        name="category"
        placeholder="Select Book / Note Book"
        optionFilterProp="children"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
          <Option key="book" value="book">
             Text Book 
          </Option>
          <Option key="notebook" value="notebook">
             Note Book 
          </Option>
      </Select>
      </Form.Item> */}
     
     
      
        <Form.Item
        name="classId"
        label={
          <span>
            Select Class &nbsp;
           
          </span>
        }
      // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
      >
        <Select
        showSearch
        //style={{width: 200}}
        name="classId"
        placeholder="Select a class"
        optionFilterProp="children"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
       {classList.map(item => (
          <Option key={item.id} value={item.id}>
            {item.classname}
          </Option>
        ))}
       
      </Select>
      </Form.Item>

      
      <Form.Item
        name="subjectId"
        label={
          <span>
            Select Subject &nbsp;
          </span>
        }
       // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
      >
        <Select
        showSearch
        //style={{width: 200}}
        name="subjectId"
        placeholder="Select a subject"
        optionFilterProp="children"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
       {subjectListByClass.map(item => (
          <Option key={item.id} value={item.id}>
            {item.subjectname}
          </Option>
        ))}
       
      </Select>
      </Form.Item>

      <Form.Item
        name="name"
        label={
          <span>
            Note Book Name&nbsp; 
           
          </span>
        }
    
      >
        <Input  placeholder="Enter Book Name" />
        </Form.Item>
        <Form.Item
            name="credit"
            placeholder="Number of book"
            label={
              <span>
                Number Of Stock &nbsp; 
              </span>
            }
            rules={[{ required: true, message: 'Please enter number of book', whitespace: true }]}
      >
       <Input type="number" name="credit" placeholder={'Enter number of books'} />
        </Form.Item>
        
       
     

        <Form.Item
            name="numberofpage"
            label={
              <span>
                Number Of Pages &nbsp;
              
              </span>
            }
           // rules={[{ required: true, message: 'Please enter number of page', whitespace: true }]}
      >
        
        <Input type="number" name="numberofpage" placeholder={'Enter number of book page'} />
        </Form.Item>

        {/* <Form.Item
        name="amount"
      
        label={
          <span>
            Amount &nbsp;
           
          </span>
        }
       
      >
        <Input type="number" placeholder={'Amount for per book'} />
        </Form.Item> */}
     
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" loading={loading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <NotificationContainer/>
    </Card>
   
  </div>
  );
};

export  default Registration;

