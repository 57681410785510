import React from "react";
import {Route, Switch,HashRouter} from "react-router-dom";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";

import Books from "./Books/CreateBook";
import BooksList from "./Books/BookList";
import BooksAll from "./Books/Books";
import EditBook from "./Books/EditBook";
import AddBook from "./Books/AddBooks";

import BooksReport from "./StockReport/BookList";
import NoteBookReport from "./StockReport/NoteBookReport";
import StationeryReport from "./StockReport/StationeryReport";

import NoteBooks from "./NoteBooks/CreateBook";
import NoteBooksType from "./NoteBooks/NoteBooks";
import NoteBooksList from "./NoteBooks/BookList";
import AddNoteBook from "./NoteBooks/AddNoteBook";

import AddStationery from "./Stationery/CreateStationery";
import StationeryList from "./Stationery/StationeryList";
import editStationery from "./Stationery/EditStationery";
import Stationery from "./Stationery/Stationery";

import Class from "./Classes/CreateClass";
import ClassList from "./Classes/ClassList";
import editClass from "./Classes/editClass";


import Subject from "./Subject/CreateSubject";
import SubjectList from "./Subject/SubjectList";
import editSubject from "./Subject/EditSubject";

import StockIssue from "./StockIssue/index";
import StockIssueList from "./StockIssue/stocklist";

import ClassAssign from "./ClassAssign/index";
import ClassWiseList from "./ClassAssign/stocklist";
import ClassAssignEdit from "./ClassAssign/edit";

import students from "./Students/students";
import AddStudent from "./Students/index";

import Visitors from "./Visitors/visitorlist";

import asyncComponent from "../../util/asyncComponent";
///Provider import
import { FormProvideDetail} from '../../util/dataProvider';

const Main = ({match}) => (
  <Switch>
    <FormProvideDetail>
    <Route path={`${match.url}/books`} component={Books}/>
    <Route path={`${match.url}/books-list`} component={BooksList}/>
    <Route path={`${match.url}/add-books`} component={AddBook}/>
    <Route path={`${match.url}/books-type`} component={BooksAll}/>
    <Route path={`${match.url}/class-assign`} component={ClassAssign}/>
    <Route path={`${match.url}/class-assign-edit`} component={ClassAssignEdit}/>
    
    <Route path={`${match.url}/class-wise-list`} component={ClassWiseList}/>
    <Route path={`${match.url}/books-report`} component={BooksReport}/>
    <Route path={`${match.url}/notebooks-report`} component={NoteBookReport}/>
    <Route path={`${match.url}/stationery-report`} component={StationeryReport}/>
    <Route path={`${match.url}/edit-book`} component={EditBook}/>
    <Route path={`${match.url}/notebooks`} component={NoteBooks}/>
    <Route path={`${match.url}/notebooks-list`} component={NoteBooksList}/>
    <Route path={`${match.url}/add-notebooks`} component={AddNoteBook}/>
    <Route path={`${match.url}/notebooks-type`} component={NoteBooksType}/>
    <Route path={`${match.url}/stationery`} component={AddStationery}/>
    <Route path={`${match.url}/stationery-list`} component={StationeryList}/>
    <Route path={`${match.url}/edit-stationery`} component={editStationery}/>
    <Route path={`${match.url}/stationery-type`} component={Stationery}/>
    <Route path={`${match.url}/class`} component={Class}/>
    <Route path={`${match.url}/class-list`} component={ClassList}/>

    <Route path={`${match.url}/class-edit`} component={editClass}/>

    <Route path={`${match.url}/subject`} component={Subject}/>
    <Route path={`${match.url}/subject-list`} component={SubjectList}/>
    <Route path={`${match.url}/subject-edit`} component={editSubject}/>
    <Route path={`${match.url}/stock-issue`} component={StockIssue}/>
    <Route path={`${match.url}/stock-issue-list`} component={StockIssueList}/>

    <Route path={`${match.url}/students`} component={students}/>
    <Route path={`${match.url}/add-student`} component={AddStudent}/>
    
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/widgets`} component={Widgets}/>
    <Route path={`${match.url}/metrics`} component={Metrics}/>
    <Route path={`${match.url}/layouts`} component={Layouts}/>
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/>
    <Route path={`${match.url}/visitors`} component={Visitors}/>
    
    </FormProvideDetail>
  </Switch>
);

export default Main;
