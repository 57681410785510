import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card, Form, Input, Select, DatePicker } from 'antd';

import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import { useFormDetail } from '../../../util/dataProvider';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {NotificationContainer, NotificationManager} from "react-notifications";

const FormItem = Form.Item;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

const StudentStockForm = () => {
	const [form] = Form.useForm();
	const {
		loading,
		createSubject,
		success,
		setSuccess,
		getClassList,
		classList,
		createCat,
		getSubjectListByClass,
		subjectListByClass,
		stockIssue,
		getStationeryList,
		stationeryList,
		Createclasswise,
		getBookListAll,
		bookListAll,
	} = useFormDetail();
	const [classId, setClassId] = useState('');

	useEffect(() => {
		getClassList();
		getStationeryList();
	}, []);

	const onFinish = (values) => {
		console.log('Received values of form: ', values);
		Createclasswise(values);
	};

	useEffect (() => {
    
		if(success){
		 form.resetFields();
		 NotificationManager.success('Book Assigned Successfully', 'Success', 1000);
		 setSuccess(false);
		}
	   }, [success]);

	const handleChangeClass = (value) => {
		setClassId(value);
		getSubjectListByClass({ classId: value });
		getBookListAll({ classId: value });
	};

	
	//handleChangeSubjectByBook
	const handleChangeSubjectByBook = (fields) => {
		console.log(fields);
	}
	
	return (
		
		<Form
			form={form}
			autoComplete="off"
			//initialValues={{ remember: true }}
			name="basic"
			onFinish={onFinish}
			//onFinishFailed={this.onFinishFailed}
			layout="inline"
		>
				<Card className="gx-card" title="Class wise assign" style={{width:'100%'}} >
					<Row>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem
								 name="classId"
								 rules={[
									{
										required: true,
										message: "Please choose class."
									}
								]}
								label={<span>Select Class &nbsp;</span>}
							>
								<Select
									showSearch
									style={{ width: 250 }}
									placeholder="Select a class"
									optionFilterProp="children"
									onChange={handleChangeClass}
									//onFocus={handleFocus}
									//onBlur={handleBlur}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{classList &&
										classList.map((item) => (
											<Option key={item.id} value={item.id}>
												{item.classname}
											</Option>
										))}
								</Select>
							</FormItem>
						</Col>
						
					</Row>
				</Card>

			
		
				{/* Books formlist */}
				<Card className="gx-card" title="Add Books entry" style={{width:'100%'}}>
				
						<Form.List name="books" >
							{(fields, { add, remove }) => {
								return (
									<div>
									<Row>
										{fields.map((field, index) => (
											<>
											<Col span={7}>
												<Form.Item
													label={false}
													{...field}
													name={[field.name, 'subjectId']}
													rules={[
														{
															required: true,
															message: "Please select subject"
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select subject"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{subjectListByClass &&
														subjectListByClass.map((item) => (

															<Option key={item.id} value={item.id}>
																{item.subjectname}
															</Option>
														))}
												</Select>
												</Form.Item>
											
											</Col>
											<Col span={7}>
												<Form.Item
													label={false}
													{...field}
													name={[field.name, 'bookId']}
													rules={[
														{
															required: true,
															message: "Please select Book"
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select Text Book"
													optionFilterProp="children"
													onChange={()=>handleChangeSubjectByBook(fields)}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{bookListAll &&
														bookListAll.map((item) => {
														
															return(
																(item.category == 'book') && (
															<Option key={item.id} value={item.id}>
																{item.name}
															
															</Option>
															)
														)})}
												</Select>
												</Form.Item>
											
											</Col>
											<Col span={7}>
											
												<FormItem {...field} name={[field.name, 'qty']} label={false}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="Enter Quantity" />
												</FormItem>
											</Col>
											<Col span={3}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{ marginTop:10}}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '20%' }}
											>
												<PlusOutlined /> Add Books
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List>
					
				</Card>
			
				{/* Note formlist */}
				<Card className="gx-card" title="Note book entry" style={{width:'100%'}}>
						<Form.List name="notebooks">
							{(fields, { add, remove }) => {
								return (
									<div>
									<Row>
										{fields.map((field, index) => (
											<>
											<Col span={7}>
												<Form.Item
													label={false}
													{...field}
													name={[field.name, 'subjectId']}
													rules={[
														{
															required: true,
															message: "Please select subject"
														}
													]}
													
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select subject"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{subjectListByClass &&
														subjectListByClass.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.subjectname}
															</Option>
														))}
												</Select>
												
												</Form.Item>
											
											</Col>
											<Col span={7}>
												<Form.Item
													label={false}
													{...field}
													name={[field.name, 'notebookId']}
													rules={[
														{
															required: true,
															message: "Please select Book"
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select Note Book"
													optionFilterProp="children"
													onChange={()=>handleChangeSubjectByBook(fields)}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{bookListAll &&
														bookListAll.map((item) => {
														
															return(
																(item.category == 'notebook') && (
															<Option key={item.id} value={item.id}>
																{item.name}
															
															</Option>
															)
														)})}
												</Select>
												</Form.Item>
											
											</Col>
											<Col span={7}>
												<FormItem {...field} name={[field.name, 'qty']} label={false}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="qty" />
												</FormItem>
											</Col>
											<Col span={3}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{marginTop:10}}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '25%' }}
											>
												<PlusOutlined /> Issue note book
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List>
				</Card>	

				{/* Stationary formlist */}
				<Card className="gx-card" title="Stationary entry" style={{width:'100%'}}>
						<Form.List name="stationery">
							{(fields, { add, remove }) => {
								return (
									<div>
										<Row>
										{fields.map((field, index) => (
											<>
											<Col span={10}>
												<Form.Item
													label={false}
													{...field}
													name={[field.name, 'name']}
													rules={[
														{
															required: true,
															message: "Please enter book name."
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select a stationary"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{stationeryList &&
														stationeryList.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.name}
															</Option>
														))}
												</Select>
												
												</Form.Item>
											
											</Col>
											<Col span={10}>
											
												<FormItem {...field} name={[field.name, 'qty']} label={false}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="qty" />
												</FormItem>
											</Col>
											<Col span={4}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{ margin: '0 8px' }}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
									<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '25%' }}
											>
												<PlusOutlined /> Issue stationary
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List>
				</Card>		
			
				<Card className="gx-card"  style={{width:'100%'}}>
						<Button type="primary" htmlType="submit" className="gx-mb-3 gx-btn-blue"> Submit </Button>
				</Card>
				<NotificationContainer/>
	</Form>
	);
};

export default StudentStockForm;
