import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Card,
  AutoComplete,Table
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormDetail } from '../../../util/dataProvider';
import {NotificationContainer, NotificationManager} from "react-notifications";
import { useHistory } from "react-router-dom";
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

  
  // rowSelection object indicates the need for row selection
  

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const EditStationery = () => {
  const [form] = Form.useForm();
  let history = useHistory();
  const {  loading,createSubject,success,setSuccess,getClassList,editStationery,updateStationeryCat } = useFormDetail();

  useEffect (() => {
   if(success){
    //form.resetFields();
    NotificationManager.success('Book updated Successfully', 'Success', 2000);
    setSuccess('');
   // form.resetFields();
    setTimeout(() => {
        history.push("/main/stationery-list");
        }, 1000);

   }
  }, [success]);

  useEffect (() => {
    getClassList()
   }, []);


  const onFinish = values => {
    console.log('Received values of form: ', editStationery);
    values.category='stationery';
    updateStationeryCat(values,editStationery.id)
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  function handleBlur() {
    console.log('blur');
  }

  function handleFocus() {
    console.log('focus');
  }
  
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = value => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net'].map(domain => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map(website => ({
    label: website,
    value: website,
  }));
  const Option = Select.Option;
  return (
      <div>
    <Card className="gx-card" title="Add Stationery Stock">
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
     
      
      <Form.Item
        name="name"
        initialValue={editStationery.name}
        label={
          <span>
          Stationery Name&nbsp;
           
          </span>
        }
        rules={[{ required: true, message: 'Please Stationery!', whitespace: true }]}
      >
        <Input />
        </Form.Item>
      
        <Form.Item
            name="credit"
            placeholder="Number of item"
            initialValue={editStationery.credit}
            label={
              <span>
                Number Of item &nbsp; 
              </span>
            }
            rules={[{ required: true, message: 'Please enter total stock', whitespace: true }]}
      >
       <Input type="number" initialValue={editStationery.credit} name="credit" placeholder={'Enter total stock'} />
        </Form.Item>

        {/* <Form.Item
        name="amount"
      initialValue={editStationery.amount}
        label={
          <span>
            Amount &nbsp;
           
          </span>
        }
      
      >
        <Input type="number" placeholder={'Amount for per item'} />
        </Form.Item> */}
     
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" loading={loading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    <NotificationContainer/>
    </Card>
   
  </div>
  );
};

export  default EditStationery;

